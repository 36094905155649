<template>
  <b-container fluid class="py-4 bg-white">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
          <b-container fluid>
            <b-row class="mb-5">
              <b-col>
                <div class="mb-5"><b-link :to="{ name: 'Accounts' }">all accounts</b-link></div>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'AccountEdit', params: { id: item.id, }}">Edit</b-button>
                  {{item.organisation}} [{{item.id}}]
                </h2>
                <h4>expiry: {{item.expiry}}</h4>
                <b-button class="mt-4" v-b-toggle.collapse-1 variant="outline-primary">show key</b-button>
                <b-collapse id="collapse-1" class="mt-2">
                  <div style="font-family: courier; font-size: 1.2em"><strong>{{item.key}}</strong></div>
                </b-collapse>
              </b-col>
            </b-row>
            <b-row class="my-5" v-if="item.demo">
              <b-col>
                <div class="my-4"><b-alert show><strong>Demo account</strong></b-alert></div>
                <div><strong>licensee: </strong>{{item.demoLicensee}}</div>
                <div><strong>commencement date: </strong>{{item.demoCommencementDate}}</div>
                <div><strong>initial term: </strong>{{item.demoInitialTerm}}</div>
              </b-col>
            </b-row>
            <b-row class="my-5">
              <b-col>
                <time-series :account="item.organisation" />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <div v-if="item.publishers === 'ALL'"><strong>All publishers</strong></div>
                <div v-else>
                  <div><strong>Publishers - {{item.publishers.length}}</strong></div>
                  <div v-for="publisher in item.publishers" :key="publisher">
                    {{publisher}}
                  </div>
                </div>
              </b-col>
              <b-col>
                <div><strong>Topics - {{item.topics.length}}</strong></div>
                <div v-if="item.topics.length > 0">
                  <div v-for="topic in topicsSelected" :key="'topic' + topic.id">
                    {{topic.name}}
                  </div>
                </div>
                <div class="mt-4"><strong>Special permissions - {{item.acgroups.length}}</strong></div>
                <div v-if="item.acgroups.length > 0">
                  <div v-for="acgroup in item.acgroups" :key="acgroup">
                    {{acgroup}}
                  </div>
                </div>
                <div class="mt-4"><strong>Homepage</strong></div>
                <div>
                  {{ item.homepage }}
                </div>
              </b-col>
            </b-row>
            <b-row class="my-5">
              <b-col>
                <delete-standard :id="item.id" model="account" @deleted="onDelete"></delete-standard>
              </b-col>
            </b-row>
          </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import DeleteStandard from '@/components/DeleteStandard'
import TimeSeries from '@/components/stats/TimeseriesDetailedAccount'

export default {
  name: 'Regulator',
  components: {
    DeleteStandard,
    TimeSeries
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'account', action: 'open account', model: 'account', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('account').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('account').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      permission: {
        edit: false,
        read: false
      },
      savingStep: false,
      topicsSelected: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const topics = await this.$Amplify.API.get('cosmos', '/topics/simple')
        this.item = await this.$Amplify.API.get('cosmos', `/standard/account/item/${this.$route.params.id}`)
        document.title = this.item.organisation
        if (this.item.publishers !== 'ALL') {
          this.item.publishers = JSON.parse(this.item.publishers)
          this.item.publishers.sort()
        }
        this.item.acgroups = JSON.parse(this.item.acgroups)
        this.item.topics = JSON.parse(this.item.topics)
        const topicsSelected = this.item.topics.map(x => { return { id: x } })
        this.topicsSelected = _.intersectionBy(topics, topicsSelected, 'id')
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    onDelete: function () {
      this.$router.push({ name: 'Accounts' })
    }
  }
}
</script>
