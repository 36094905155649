<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form inline class="mb-4">
          <label class="mr-2" for="dateInput">from</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
          <label class="mr-2" for="dateInput">to</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
          <b-form-select class="mr-2" v-model="userType" :options="userTypeOptions" @change="updateActions" />
          <b-form-select class="mr-2" v-model="interval" :options="intervalOptions" />
          <b-form-checkbox class="mr-2" id="checkbox-user-unique" v-model="userUnique" value="unique" unchecked-value="all">Unique users</b-form-checkbox>
          <b-form-select class="mr-2" v-model="col" :options="colOptions" @change="updateActions" />
          <b-form-select class="mr-2" v-if="showActionOptions" v-model="action" :options="actionOptions" />
          <b-button variant="primary" v-if="showActionOptions" v-on:click="reload">Apply</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="chart" style="width: 100%; height: 500px;"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button class="mt-4" v-b-toggle.collapse-show-data variant="outline-primary">show data</b-button>
        <b-collapse id="collapse-show-data" class="mt-2">
          <div v-for="row in graphData" :key="'graph' + row.datex">
            {{moment(row.datex).format('YYYY-MM-DD')}} - {{row.count}}
          </div>
        </b-collapse>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

export default {
  name: 'TimeseriesDetailedAccount',
  props: ['account'],
  created: async function () {
    await this.updateActions()
    this.action = this.account
    this.load()
  },
  data () {
    return {
      endDate: moment().add(2, 'days').format('YYYY-MM-DD'),
      startDate: moment().subtract(2, 'years').format('YYYY-MM-DD'),
      action: null,
      actionOptions: [],
      col: 'user',
      colOptions: [
        { value: null, text: "Select Column" },
        { value: 'action', text: 'actions' },
        { value: 'model', text: 'models' },
        { value: 'page', text: 'pages' },
        { value: 'user', text: 'users' }
      ],
      graphData: [],
      intervalOptions: ['day', 'week', 'month', 'quarter', 'year'],
      interval: 'day',
      moment: moment,
      userType: 1,
      userTypeOptions: [
        { value: 0, text: 'internal users' },
        { value: 1, text: 'external users' }
      ],
      userUnique: false,
      showActionOptions: true
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loading timeseries-detailed started')
      this.loadingNewsRisk = true
      try {
        const apiName = 'cosmos'
        let path = `/stats/timeseries-detailed/${this.userType}/${this.col}/${this.action}/${this.startDate}/${this.endDate}/${this.interval}`
        if (this.userUnique === 'unique') {
          path = `/stats/timeseries-detailed-uniq-user/${this.userType}/${this.col}/${this.action}/${this.startDate}/${this.endDate}/${this.interval}`
        }
        const graphData = await this.$Amplify.API.get(apiName, path)
        this.graphData = graphData
        console.log(graphData)
        const optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chart'), 'kpmg').setOption(optionTrend)
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    reload: function () {
      echarts.dispose(document.getElementById('chart'))
      this.load()
    },
    updateActions: async function () {
      try {
        // it's good to set visibility flag to false so that forms don't who while we are updating data
        this.showActionOptions = false
        const apiName = 'cosmos'
        const path = `/stats/unique/${this.userType}/${this.col}/${this.startDate}/${this.endDate}`
        const dropdownRawData = await this.$Amplify.API.get(apiName, path)
        const actionArray = [{ "value": null, "text": "Select " + this.col }]
        _.each(dropdownRawData, x => actionArray.push({ "value": x, "text": x }))
        this.actionOptions.sort()
        this.actionOptions = actionArray
        // assign null to this.action so that by default the first option "select..." is visible
        this.action = null
        this.showActionOptions = true
      } catch (e) {
        this.$logger.warn('error updating actions:' + e)
      }
    }
  }
}
</script>

<style></style>
